import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue")
  },
  {
    path: "/result",
    name: "Result",
    component: () => import("../views/Result.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to) {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const compare = width < height;
    if (to.hash) {
      if (to.hash === "#good-news" && compare) {
        return {
          selector: to.hash,
          offset: {x: 0, y: -60},
        };
      }
      return {
        selector: to.hash,
        offset: {x: 0, y: 100},
      };
    }
  },
});

export default router;
