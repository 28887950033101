<template>
  <section class="links" id="links">
    <div class="container links__container">
      <h4 class="links__title">Джерела</h4>
      <ol class="links__list">
        <li class="links__item">Інструкція до CITO TEST® гепатит&nbsp;В.</li>
        <li class="links__item">
          Українців закликають пройти безоплатний тест на вірусні гепатити
          (moz.gov.ua). URL:
          <a
              href="https://moz.gov.ua/article/news/ukrainciv-zaklikajut-projti-bezoplatnij-test-na-virusni-gepatiti"
              target="_blank"
          >https://moz.gov.ua/article/news/ukrainciv-zaklikajut-projti-bezoplatnij-test-na-virusni-gepatiti</a
          >
          (дата звернення: 03.09.2021).
        </li>
        <li class="links__item">
          Вірусний гепатит&nbsp;С. Клінічна настанова, заснована на доказах, 2020.
          Державний експертний центр МОЗ України, ДУ «Центр громадського
          здоров’я МОЗ України». URL:
          <a
              target="_blank"
              href="https://www.dec.gov.ua/wp-content/uploads/2021/01/2021_50_51_kn_vgc.pdf"
          >https://www.dec.gov.ua/wp-content/uploads/2021/01/2021_50_51_kn_vgc.pdf</a
          >
          (дата звернення: 03.09.2021).
        </li>
        <li class="links__item">
          Стандарти медичної допомоги «Вірусний гепатит&nbsp;С у дорослих». Наказ МОЗ
          України від 15 січня 2021 року № 51. URL:
          <a
              target="_blank"
              href="https://moz.gov.ua/uploads/5/28274-dn_51_15_01_2021_dod.pdf"
          >https://moz.gov.ua/uploads/5/28274-dn_51_15_01_2021_dod.pdf</a
          >
          (дата звернення: 03.09.2021).
        </li>
        <li class="links__item">
          Стандарти медичної допомоги «Вірусний гепатит&nbsp;В у дорослих». Наказ МОЗ
          України від 15 січня 2021 року № 49. URL:
          <a
              target="_blank"
              href="https://moz.gov.ua/uploads/5/28271-dn_49_15_01_2021_dod.pdf"
          >https://moz.gov.ua/uploads/5/28271-dn_49_15_01_2021_dod.pdf</a
          >
          (дата звернення: 03.09.2021).
        </li>
        <li class="links__item">
          Руководство по тестированию на гепатиты В и С [Guidelines on hepatitis
          B and C testing]. Февраль, 2017. URL:
          <a
              target="_blank"
              href="http://apps.who.int/iris/bitstream/handle/10665/260130/9789244549988-rus.pdf"
          >http://apps.who.int/iris/bitstream/handle/10665/260130/9789244549988-rus.pdf</a
          >
          (дата звернення: 03.09.2021).
        </li>
        <li class="links__item">Інструкція до CITO TEST® гепатит&nbsp;С.</li>
        <li class="links__item">
          Рекомендації щодо тестування на вірусні гепатити В та С у межах
          медичних послуг з надання первинної медичної допомоги із використанням
          швидких тестів, 2019. URL:
          <a
              target="_blank"
              href="https://phc.org.ua/sites/default/files/users/user90/VG_rekomendatsii_A4_CMYK_web.pdf"
          >https://phc.org.ua/sites/default/files/users/user90/VG_rekomendatsii_A4_CMYK_web.pdf</a
          >
          (дата звернення: 03.09.2021).
        </li>
        <li class="links__item">
          МОЗ України оголошує місяць вчасного виявлення вірусних гепатитів
          (moz.gov.ua)
          <a
              target="_blank"
              href="https://moz.gov.ua/article/news/moz-ukraini-ogoloshue-misjac-vchasnogo-vijavlennja-virusnih-gepatitiv- "
          >https://moz.gov.ua/article/news/moz-ukraini-ogoloshue-misjac-vchasnogo-vijavlennja-virusnih-gepatitiv- </a
          >(дата звернення: 03.09.2021).
        </li>
        <li class="links__item">
          Гепатити: шляхи передавання та профілактика (phc.org.ua). URL:
          <a
              target="_blank"
              href="https://phc.org.ua/news/gepatiti-shlyakhi-peredavannya-ta-profilaktika"
          >https://phc.org.ua/news/gepatiti-shlyakhi-peredavannya-ta-profilaktika</a
          >
          (дата звернення: 03.09.2021).
        </li>
        <li class="links__item">ДВ №22 з 10.04.2020 р. до 05.12.2023 р.</li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.links {
  padding: 100px 0 65px 0;

  background: #f2f1f1;
  border-bottom: 1px solid #b8b7b7;

  @media screen and (min-width: 768px) {
    padding: 170px 0 65px;
  }

  &__title {
    margin-bottom: 18px;

    font-size: 16px;
    line-height: 20px;

    @media screen and (min-width: 1920px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__list {
    list-style: decimal;
    margin-left: 20px;
  }

  &__item {
    font-size: 12px;
    line-height: 18px;

    color: #000000;

    opacity: 0.75;

    &:last-child {
      list-style-type: "* ";
    }

    @media screen and (min-width: 1920px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
