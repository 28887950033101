<template>
  <section
    class="hepatit hepatit--b watch"
    :class="{ animated: getVisibleStage.includes('hepatit-b') }"
    id="hepatit-b"
  >
    <div class="container hepatit__container">
      <div class="hepatit__title" id="hepatit-b">
        <h2 class="secondary-title hepatit__title">
          <p class="a-title">
            Перевіртеся <br />
            на <span>гепатит&nbsp;B</span> вдома
          </p>
        </h2>
        <p class="hepatit__subtitle a-title mt">
          без черг та тривалого очікування результату
        </p>
      </div>

      <div class="hepatit__desc">
        <div class="hepatit__card">
          <div class="hepatit__sticky">
            <div class="hepatit__pack">
              <img
                srcset="
                  @/assets/img/banner_pack-1(x2).png 1024w,
                  @/assets/img/banner_pack-1(x3).png 1920w
                "
                sizes="(max-width: 640px) 200px,
                            (max-width: 1024px) 250px,
                            272px"
                src="@/assets/img/banner_pack-1(x1).png"
                alt=""
                decoding="async"
                loading="lazy"
                class="hepatit__img"
              />
            </div>
            <div class="hepatit__buttons">
              <div class="hepatit__links">
                <a
                  href="https://tabletki.ua/uk/Cito-Test-HBsAg/1010733/"
                  class="hepatit__link"
                  target="_blank"
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                      fill="#ED0000"
                    />
                  </svg>
                  <p>Знайти в аптеці</p>
                </a>
                <a
                  href="https://apteka.rozetka.com.ua/cito_test_4820235550097/p311912678/"
                  class="hepatit__link"
                  target="_blank"
                >
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.9917 5.60682L16.2788 9.86971C16.1909 10.3406 15.8309 10.6269 15.4158 10.6323H5.02233L4.81598 12.8291H14.7217C15.2433 12.8704 15.5971 13.2555 15.6034 13.7481C15.5655 14.2907 15.196 14.6605 14.7217 14.6672H3.76536C3.17281 14.6117 2.81948 14.1193 2.8836 13.5722L3.35262 9.92834L2.63971 2.45849L0.613546 1.79368C0.118407 1.58877 -0.0836807 1.1219 0.0319618 0.639961C0.224246 0.137694 0.684989 -0.091785 1.13885 0.0337707L3.72783 0.894174C4.07662 1.03045 4.27726 1.32803 4.32817 1.67636L4.47826 3.1625L16.2225 4.53132C16.7621 4.65315 17.0551 5.09866 16.9917 5.60682ZM6.44993 16.597C6.44993 17.3718 5.84728 18 5.10384 18C4.36042 18 3.75777 17.3718 3.75777 16.597C3.75777 15.8221 4.36043 15.1939 5.10384 15.1939C5.84727 15.1939 6.44993 15.8221 6.44993 16.597ZM14.486 16.597C14.486 17.3718 13.8833 18 13.1399 18C12.3965 18 11.7938 17.3718 11.7938 16.597C11.7938 15.8221 12.3965 15.1939 13.1399 15.1939C13.8833 15.1939 14.486 15.8221 14.486 16.597Z"
                      fill="#ED0000"
                    />
                  </svg>
                  <p>Купити на Rozetka</p>
                </a>
              </div>
              <a
                href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173220&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=hepatit_b"
                class="hepatit__btn"
                target="_blank"
              >
                <svg
                  width="26"
                  height="25"
                  viewBox="0 0 26 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                    fill="#F3E6A1"
                  />
                  <path
                    d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                    stroke="#F3E6A1"
                  />
                  <path
                    d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                    stroke="#F3E6A1"
                  />
                  <path
                    d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                    stroke="#F3E6A1"
                  />
                  <path
                    d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                    stroke="#F3E6A1"
                  />
                  <path
                    d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                    stroke="#F3E6A1"
                  />
                  <path
                    d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                    stroke="#F3E6A1"
                  />
                </svg>
                <p>Купити онлайн</p>
              </a>
            </div>
          </div>
          <!-- For correct sticky position -->
          <div></div>
          <!--  -->
        </div>
        <div class="hepatit__details">
          <ul class="hepatit__list">
            <li class="hepatit__item">
              Результат через
              <span> 15 хв<sup>1</sup> </span>
            </li>
            <li class="hepatit__item">
              Точність понад
              <span> 99,9%<sup>1</sup> </span>
            </li>
            <li class="hepatit__item">
              Анонімно
              <span>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 39 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_322:54414)">
                    <path
                      d="M35.4217 18.7508C34.0778 16.6638 31.5852 12.7972 31.5852 12.7972C30.2232 6.79962 26.7247 2.53803 22.4606 0.864649C19.6257 -0.247662 14.7233 -0.271933 11.701 0.705702C4.42454 3.05802 1.38828 10.8456 3.93968 18.7368C4.62996 20.8721 5.72768 22.7469 7.09086 24.3162L4.5612 35.9246C4.39757 36.6744 4.58285 37.4576 5.06405 38.0549C5.54518 38.6529 6.27146 38.9999 7.03852 38.9999H15.7176C17.0117 38.9999 18.098 38.0255 18.2383 36.7388L18.8152 31.4401C19.4011 31.5643 19.996 31.648 20.606 31.648C25.7574 31.648 30.1185 28.3558 31.575 22.0205L34.2745 22.133C35.0084 22.0048 35.6119 21.4864 35.8505 20.7819C36.0907 20.0774 35.8763 19.4565 35.4217 18.7508ZM17.6193 25.6125C16.0788 25.6125 15.0335 24.4791 15.0335 22.9684C15.0335 21.4286 16.107 20.3244 17.6193 20.3244C19.1867 20.3244 20.2053 21.4286 20.2333 22.9684C20.2333 24.4791 19.1867 25.6125 17.6193 25.6125ZM21.1346 14.8042C20.0586 15.9952 19.6233 17.1286 19.6515 18.4354C19.6515 18.725 19.4179 18.9587 19.1294 18.9587H16.2908C16.0098 18.9587 15.7802 18.7368 15.7688 18.4565L15.7598 18.2039C15.6719 16.7214 16.1645 15.2107 17.4729 13.6415C18.402 12.5376 19.1562 11.6078 19.1562 10.6204C19.1562 9.60324 18.4901 8.9343 17.0376 8.8768C16.5945 8.8768 16.1084 8.95088 15.6387 9.08239C14.7758 9.32422 13.8761 8.83611 13.6094 7.97896C13.341 7.11998 13.8107 6.20674 14.6646 5.9228C15.604 5.61061 16.7528 5.39051 18.024 5.39051C21.8609 5.39051 23.6018 7.51124 23.6018 9.92233C23.6017 12.1311 22.2372 13.5835 21.1346 14.8042Z"
                      fill="#ED0000"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_322:54414">
                      <rect width="39" height="39" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </li>
            <li class="hepatit__item">
              Проста процедура <br />для самотестування
              <span>
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 15.4999C0.5 7.2293 7.22945 0.5 15.4999 0.5C23.7705 0.5 30.4998 7.2293 30.4998 15.4999C30.4998 23.7705 23.7704 30.5 15.4999 30.5C7.22946 30.5 0.5 23.7705 0.5 15.4999ZM15.016 22.9732L24.7538 13.2354C25.8037 12.1855 25.8036 10.4784 24.7535 9.42825C24.2456 8.9204 23.568 8.63986 22.85 8.63986C22.1319 8.63986 21.4543 8.9204 20.9465 9.42825L13.1123 17.2624L10.0532 14.2034C10.0531 14.2034 10.0531 14.2033 10.053 14.2033C9.54533 13.6952 8.86757 13.4146 8.14954 13.4146C7.43129 13.4146 6.75371 13.6953 6.24604 14.2031C5.73824 14.7109 5.45749 15.3885 5.45749 16.1068C5.45749 16.8249 5.73825 17.5025 6.24604 18.0103L11.209 22.9732C11.7168 23.4811 12.3944 23.7616 13.1125 23.7616C13.8305 23.7616 14.5081 23.4811 15.016 22.9732Z"
                    fill="#ED0000"
                    stroke="#ED0000"
                  />
                </svg>
              </span>
            </li>
            <li class="hepatit__item">
              Можна робити вдома
              <span>
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_322:54418)">
                    <path
                      d="M30.6114 15.137C31.1733 14.519 31.1191 13.5717 30.4902 13.0218L16.6359 0.899197C16.0071 0.349273 14.9989 0.360792 14.3833 0.925167L0.481894 13.6681C-0.133714 14.2325 -0.163928 15.1785 0.414998 15.7803L0.763525 16.1433C1.34174 16.745 2.27637 16.8168 2.84974 16.303L3.88865 15.3727V28.9937C3.88865 29.8294 4.5658 30.5059 5.40089 30.5059H10.8197C11.6548 30.5059 12.3319 29.8294 12.3319 28.9937V19.4645H19.2437V28.9937C19.2317 29.8287 19.8292 30.5053 20.6643 30.5053H26.4068C27.2419 30.5053 27.9191 29.8287 27.9191 28.993V15.5645C27.9191 15.5645 28.2061 15.8159 28.56 16.127C28.9134 16.4375 29.6556 16.1886 30.2176 15.5699L30.6114 15.137Z"
                      fill="#ED0000"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_322:54418">
                      <rect width="31" height="31" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </li>
          </ul>
          <div class="hepatit__video">
            <!--            <button @click="setIsActiveVideo(true)" class="video__btn">-->
            <!--              <svg-->
            <!--                width="21"-->
            <!--                height="24"-->
            <!--                viewBox="0 0 21 24"-->
            <!--                fill="none"-->
            <!--                xmlns="http://www.w3.org/2000/svg"-->
            <!--              >-->
            <!--                <path-->
            <!--                  d="M19.9401 10.7894C20.6067 11.1743 20.6067 12.1366 19.9401 12.5215L1.94007 22.9138C1.2734 23.2987 0.440068 22.8176 0.440068 22.0478L0.440069 1.26315C0.440069 0.493348 1.2734 0.0122245 1.94007 0.397125L19.9401 10.7894Z"-->
            <!--                  fill="#ED0000"-->
            <!--                />-->
            <!--              </svg>-->
            <!--            </button>-->
            <img src="@/assets/img/video.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <switcher title="B" />
  </section>
</template>

<script>
import Switcher from "@/components/Switcher";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "HepatitC",
  components: {
    Switcher,
  },
  computed: {
    ...mapGetters(["getVisibleStage"]),
  },
  methods: {
    ...mapMutations(["setIsActiveVideo"]),
  },
};
</script>

<style lang="scss">
.hepatit {
  position: relative;

  &--b {
    background: #fff;

    margin: 0 0;
    padding: 0 0 24px;

    &::before {
      content: "";

      position: absolute;
      width: 150%;
      height: 109%;

      top: -6%;
      left: -25%;

      background: #fff;

      transform: rotate(4.75deg);

      @media screen and (max-width: 1023px) and (min-width: 482px){
        height: 109%;
      }

      @media screen and (min-width: 1920px) {
        height: 120%;

        top: -10%;
      }
      @media screen and (min-width: 1024px) {
        height: 120%;
      }
    }
  }
}
</style>
