<template>
  <div class="popup">
    <div class="popup__container">
      <button @click="setIsActiveSocials(false)" class="popup__closer">
        <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M16.4375 16L22.3315 21.9873C22.6318 22.2924 22.6318 22.787 22.3315 23.0921L16.4375 29.0795"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
          />
          <path
              d="M29.3125 16L23.4185 21.9873C23.1182 22.2924 23.1182 22.787 23.4185 23.0921L29.3125 29.0795"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
          />
          <circle cx="23" cy="23" r="22.5" stroke="white"/>
        </svg>
      </button>

      <h3 class="popup__title">Поділитись</h3>

      <ul class="popup__list">
        <li
            class="popup__item"
            v-for="(network, index) in networks"
            :key="network.network"
        >
          <ShareNetwork
              :network="network.network"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :media="media"
          >
            <img
                :src="require(`@/assets/img/social-${index + 1}.svg`)"
                alt=""
                class="popup__social-icon"
            />
            <p class="popup__text">
              {{ network.name }}
            </p>
          </ShareNetwork>
        </li>
      </ul>

      <div class="popup__buttons">
        <button @click="setIsActiveSocials(false)" class="popup__back">
          <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M13.3445 11.1912L8.78012 10.7949L8.78061 12.8521C8.78061 13.2462 8.35928 13.5007 8.00933 13.3127L0.492506 9.25917C0.126375 9.06311 0.126183 8.53514 0.492494 8.339L8.00923 4.28569C8.35639 4.09768 8.78051 4.35062 8.78051 4.74625L8.78008 6.80346L13.3444 6.40724C13.6454 6.38144 13.9127 6.61718 13.9127 6.92837L13.9128 10.6701C13.9128 10.9749 13.6511 11.2175 13.3445 11.1912Z"
                fill="#ED0000"
            />
          </svg>
          Назад
        </button>

        <button class="popup__button" @click="copyURL">
          <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M13.3334 0.833252H3.33341C2.41675 0.833252 1.66675 1.58325 1.66675 2.49992V14.1666H3.33341V2.49992H13.3334V0.833252ZM12.5001 4.16658L17.5001 9.16658V17.4999C17.5001 18.4166 16.7501 19.1666 15.8334 19.1666H6.65841C5.74175 19.1666 5.00008 18.4166 5.00008 17.4999L5.00841 5.83325C5.00841 4.91658 5.75008 4.16658 6.66675 4.16658H12.5001ZM11.6667 9.99992H16.2501L11.6667 5.41658V9.99992Z"
                fill="#ED0000"
            />
          </svg>

          <p>Копіювати лінк</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  data: () => ({
    sharing: {
      url: "https://gepatit.pharmasco.com.ua/",
      title: "CITO TEST",
      description: "ОНЛАЙН-ТЕСТУ НА ГЕПАТИТ НЕМАЄ, АЛЕ Є CITO TEST® ДЛЯ САМОТЕСТУВАННЯ НА ГЕПАТИТ ВДОМА",
      media: "https://gepatit.pharmasco.com.ua/share.png",
    },
    networks: [
      {network: "facebook", name: "Facebook"},
      {network: "telegram", name: "Telegram"},
      {network: "email", name: "Email"},
      {network: "viber", name: "Viber"},
      {network: "twitter", name: "Twitter"},
      {network: "whatsapp", name: "WhatsApp"},
    ],
  }),
  methods: {
    ...mapMutations(["setIsActiveSocials"]),
    copyURL() {
      const el = document.createElement("textarea");

      el.value = window.location.origin;

      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);

      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();

      document.execCommand("copy");
      document.body.removeChild(el);

      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: flex-end;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  @media screen and (min-width: 768px) {
    align-items: center;
    justify-content: center;
  }

  &__container {
    position: relative;

    display: flex;
    flex-direction: column;

    max-width: 100vw;
    width: 120%;

    padding: 40px 20px;

    background-color: #fff;

    @media screen and (min-width: 768px) {
      align-items: center;

      width: fit-content;

      margin: 0 24px;
      padding: 65px 40px;
    }
  }

  &__closer {
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);

    display: none;

    background-color: transparent;

    cursor: pointer;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  &__title {
    margin-bottom: 20px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__list {
    display: flex;
    align-items: center;

    max-width: calc(100vw - 20px);

    margin: 0 auto;

    @media screen and (max-width: 768px) {
      overflow-x: auto;
    }
  }

  &__item {
    flex-shrink: 0;

    width: 60px;

    margin-right: 24px;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &__text {
    margin-top: 5px;

    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 32px;

    @media screen and (min-width: 768px) {
      margin-top: 52px;
    }
  }

  &__back {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 14px;
    line-height: 10px;

    color: #ed0000;

    cursor: pointer;

    svg {
      margin-right: 5px;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &__button {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 48px;

    padding: 0 24px;

    border-radius: 120px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    cursor: pointer;
    transition: 0.4s;

    svg {
      margin-right: 8px;

      position: relative;
      z-index: 2;
    }

    p {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: "";

      position: absolute;
      right: 0;
      top: 0;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      border: 1px solid transparent;

      transition: top 0.4s, right 0.4s;
    }

    &::after {
      content: "";

      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      background: #f3e6a1;
      border: 1px solid #000000;
      transition: 0.4s;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        &::before {
          top: 10px;
          right: -5px;

          border-color: #000;
        }
      }
    }

    &:active {
      &::after {
        background-color: #fff;
      }

      &::before {
        top: 0;
        right: 0;
      }
    }
  }
}
</style>
