<template>
  <section
      class="other watch"
      :class="{ animated: getVisibleStage.includes('other') }"
      id="other"
  >
    <kinesis-container class="container other__container">
      <h3 class="third-title other__third-title a-title">
        вас ще можуть зацікавити інші тести для самотестування
      </h3>
      <ul class="other__list">
        <kinesis-element :strength="10">
          <li class="other__item">
            <div class="other__pack pack1">
              <img
                  srcset="
                  @/assets/img/other_pack-1(x2).png 1024w,
                  @/assets/img/other_pack-1(x3).png 1920w
                "
                  sizes="(max-width: 640px) 200px,
                            (max-width: 1024px) 250px,
                            272px"
                  src="@/assets/img/other_pack-1(x1).png"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  class="other__img"
              />
            </div>
            <div class="other__details">
              <p class="other__title a-title">CITO TEST<sup>®</sup> COVID-19</p>
              <p class="other__subtitle a-title">нейтралізуючі антитіла</p>
              <p class="other__text">
                Тест для перевірки імунітету до коронавірусу
              </p>
              <div class="other__buttons">
                <a
                    href="https://tabletki.ua/%D0%A6%D0%B8%D1%82%D0%BE-%D0%A2%D0%B5%D1%81%D1%82-%D0%9A%D0%BE%D0%B2%D0%B8%D0%B4-19-%D0%BD%D0%B0-%D0%BA%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%D0%B2%D0%B8%D1%80%D1%83%D1%81/1023550/"
                    target="_blank"
                    class="other__link"
                >
                  <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                        fill="#ED0000"
                    />
                  </svg>
                  <p>Знайти в аптеці</p>
                </a>
                <a
                    href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173227&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=covid_antibodies"
                    class="other__btn"
                    target="_blank"
                >
                  <svg
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                        fill="#f3e6a1"
                    />
                    <path
                        d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                        stroke="#f3e6a1"
                    />
                  </svg>
                  <p>
                    Купити<br/>
                    онлайн
                  </p>
                </a>
              </div>
            </div>
          </li>
        </kinesis-element>
        <kinesis-element :strength="10">
          <li class="other__item">
            <div class="other__pack pack2">
              <img
                  srcset="
                  @/assets/img/other_pack-2(x2).png 1024w,
                  @/assets/img/other_pack-2(x3).png 1920w
                "
                  sizes="(max-width: 640px) 200px,
                            (max-width: 1024px) 250px,
                            272px"
                  src="@/assets/img/other_pack-2(x1).png"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  class="other__img"
              />
            </div>
            <div class="other__details">
              <p class="other__title a-title">
                CITO TEST<sup>®</sup> COVID-19 Ag
              </p>
              <p class="other__text">
                Швидкий тест для виявлення антигенів коронавірусу при перших
                симптомах ГРВІ
              </p>
              <div class="other__buttons">
                <a
                    href="https://tabletki.ua/COVID-19-Ag/1033470/"
                    class="other__link"
                    target="_blank"
                >
                  <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                        fill="#ED0000"
                    />
                  </svg>
                  <p>Знайти в аптеці</p>
                </a>
                <a
                    href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173226&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=covid_ag"
                    class="other__btn"
                    target="_blank"
                >
                  <svg
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                        fill="#f3e6a1"
                    />
                    <path
                        d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                        stroke="#f3e6a1"
                    />
                  </svg>
                  <p>
                    Купити<br/>
                    онлайн
                  </p>
                </a>
              </div>
            </div>
          </li>
        </kinesis-element>
        <kinesis-element :strength="10">
          <li class="other__item">
            <div class="other__pack pack3">
              <img
                  srcset="
                  @/assets/img/other_pack-3(x2).png 1024w,
                  @/assets/img/other_pack-3(x3).png 1920w
                "
                  sizes="(max-width: 640px) 200px,
                            (max-width: 1024px) 250px,
                            272px"
                  src="@/assets/img/other_pack-3(x1).png"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  class="other__img"
              />
            </div>
            <div class="other__details">
              <p class="other__title a-title">CITO TEST<sup>®</sup> HIV ½</p>
              <p class="other__text">
                Експрес-тест для діагностики ВІЛ-інфекції 1 та 2 типу
              </p>
              <div class="other__buttons">
                <a
                    href="https://tabletki.ua/Cito-Test-HIV-1-2/1001639/"
                    class="other__link"
                    target="_blank"
                >
                  <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                        fill="#ED0000"
                    />
                  </svg>
                  <p>Знайти в аптеці</p>
                </a>
                <a
                    href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173229&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=hiv"
                    class="other__btn"
                    target="_blank"
                >
                  <svg
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                        fill="#f3e6a1"
                    />
                    <path
                        d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                        stroke="#f3e6a1"
                    />
                  </svg>
                  <p>
                    Купити<br/>
                    онлайн
                  </p>
                </a>
              </div>
            </div>
          </li>
        </kinesis-element>
        <kinesis-element :strength="10">
          <li class="other__item">
            <div class="other__pack pack4">
              <img
                  srcset="
                  @/assets/img/other_pack-4(x2).png 1024w,
                  @/assets/img/other_pack-4(x3).png 1920w
                "
                  sizes="(max-width: 640px) 200px,
                            (max-width: 1024px) 250px,
                            272px"
                  src="@/assets/img/other_pack-4(x1).png"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  class="other__img"
              />
            </div>
            <div class="other__details">
              <p class="other__title a-title">
                CITO TEST<sup>®</sup> H.Pylori Ag
              </p>
              <p class="other__text">
                Експрес-тест для виявлення антигенів Хелікобактер Пілорі
              </p>
              <div class="other__buttons">
                <a
                    href="https://tabletki.ua/Cito-Test-H-Pylori-Ag/1012530/"
                    class="other__link"
                    target="_blank"
                >
                  <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                        fill="#ED0000"
                    />
                  </svg>
                  <p>Знайти в аптеці</p>
                </a>
                <a
                    href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173230&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=helicobacter"
                    class="other__btn"
                    target="_blank"
                >
                  <svg
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                        fill="#f3e6a1"
                    />
                    <path
                        d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                        stroke="#f3e6a1"
                    />
                    <path
                        d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                        stroke="#f3e6a1"
                    />
                  </svg>
                  <p>
                    Купити<br/>
                    онлайн
                  </p>
                </a>
              </div>
            </div>
          </li>
        </kinesis-element>
      </ul>
    </kinesis-container>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

import {KinesisContainer, KinesisElement} from "vue-kinesis";

export default {
  name: "Other",
  components: {
    KinesisContainer,
    KinesisElement,
  },
  computed: {
    ...mapGetters(["getVisibleStage"]),
  },
};
</script>

<style lang="scss" scoped>
.other {
  position: relative;
  z-index: 1;

  padding: 10px 0 50px 0;

  @media screen and (min-width: 768px) {
    padding: 0;
  }

  &::before {
    content: "";

    position: absolute;
    width: 200%;
    height: 110%;

    top: -5.5%;
    left: -30%;

    background: #f3e6a1;

    transform: rotate(-4.75deg);

    @media screen and (min-width: 768px) {
      top: -7.5%;
      height: 112%;
    }
  }

  &__third-title {
    @media screen and (min-width: 768px) {
      max-width: 60%;
    }

    @media screen and (min-width: 1200px) {
      max-width: 50%;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;

    gap: 120px 0;

    margin-top: 85px;

    > div {

      &:nth-child(2n) {
        margin: 0 0 0 auto;
      }

      &:nth-child(2n + 1) {
        margin: 0 auto 0 0;
      }
    }


    @media screen and (min-width: 768px) {
      margin-top: 100px;
    }

    @media screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr;

      gap: 150px 0;

      margin-top: 150px;
    }
  }

  &__item {
    position: relative;

    width: 393px;
    height: 393px;


    padding: 155px 0 50px 80px;

    transform: translateX(-20%);

    background-color: #fff;

    border-radius: 50%;
    border: 1px solid #000;

    @media screen and (min-width: 768px) {
      width: 427px;
      height: 427px;

      padding: 165px 10px 50px 80px;

      transform: translateX(0);
    }

    &:nth-child(even) {
      @media screen and (min-width: 768px) {
        justify-self: flex-end;
      }
    }
  }

  &__pack {
    position: absolute;
    top: 0;
    left: 0;

    width: 80%;

    transform: translate(25%, -32%);

    @media screen and (min-width: 768px) {
      transform: translate(0, -30%);

      &.pack1 {
      }

      &.pack2 {
        transform: translate(0, -23%);
        width: 411px;
      }

      &.pack3 {
      }

      &.pack4 {
        transform: translate(0, -30%);

        width: 367px;
      }
    }

    img {
      width: 100%;
      filter: drop-shadow(16px 16px 48px rgba(82, 25, 1, 0.36));
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__title {
    margin-bottom: 2px;

    font-family: "Oswald", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;

    color: #ed0000;

    @media screen and (min-width: 1200px) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  &__subtitle {
    margin-bottom: 8px;

    color: red;
    font-weight: 500;

    font-size: 20px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__buttons {
    position: relative;
    z-index: 1;

    display: flex;

    width: 100%;

    padding: 0;
    margin-top: auto;
  }

  &__link {
    display: flex;
    align-items: center;

    width: 100%;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    p {
      position: relative;

      margin-left: 5px;

      transition: 0.4s;

      &::after {
        content: "";

        position: absolute;
        top: 105%;
        left: 0;

        width: 100%;
        height: 1px;

        background-color: #000;

        transition: 0.4s;
      }
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        p {
          &::after {
            width: 20%;
          }
        }
      }
    }

    &:active {
      p {
        color: #ed0000;

        &::after {
          background-color: #ed0000;
        }
      }
    }
  }

  &__btn {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 150px;

    padding: 12px 35px 12px 22px;
    margin-left: 20px;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    background: #ed0000;
    color: #fff;
    border: 1px solid #000000;
    border-radius: 120px;

    svg {
      margin-right: 8px;
    }

    &::before {
      content: "";

      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      border: 1px solid transparent;

      transition: top 0.4s, right 0.4s;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        &::before {
          top: 8px;
          right: -5px;

          border-color: #000;
        }
      }
    }

    &:active {
      background-color: #c83b26;

      &::before {
        top: 0;
        right: 0;
      }
    }
  }
}
</style>
