<template>
  <div id="app">
    <Header/>

    <PopupOrder
        v-if="
        getIsActiveOrder &&
        !getIsActiveSocials &&
        !getIsActiveVideo &&
        !getOneTrigger
      "
    />
    <PopupSocial
        v-if="getIsActiveSocials && !getIsActiveVideo && !getIsActiveOrder"
    />
    <PopupVideo
        v-if="getIsActiveVideo && !getIsActiveOrder && !getIsActiveSocials"
    />

    <router-view/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import Header from "@/components/Header.vue";
import PopupOrder from "@/components/PopupOrder";
import PopupVideo from "@/components/PopupVideo";
import PopupSocial from "@/components/PopupSocial";

export default {
  components: {
    Header,
    PopupOrder,
    PopupSocial,
    PopupVideo,
  },
  mounted() {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  watch: {
    $route(to, from) {
      if (from.name !== "Test" && to.name === 'Result' ) {
        this.$router.push('/test')
      }
    },
  },
  computed: {
    ...mapGetters([
      "getResult",
      "getIsActiveSocials",
      "getIsActiveOrder",
      "getIsActiveVideo",
      "getOneTrigger",
    ]),
  },
};
</script>

<style lang="scss">
@import url(assets/scss/_reset.scss);

#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  overflow-x: hidden;
  letter-spacing: 0.02em;

  @media screen and (min-width: 1100px) {
    overflow-x: visible;
  }
}

.container {
  position: relative;
  z-index: 1;

  width: 100%;
  max-width: calc(1150px + 180px);

  padding: 0 24px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding: 0 40px;
  }
}

.primary-title {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;

  text-transform: uppercase;

  color: #ffffff;

  @media screen and (min-width: 768px) {
    font-size: 72px;
    line-height: 80px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 88px;
    line-height: 96px;
  }
}

.secondary-title {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 72px;
    line-height: 75px;
  }


  @media screen and (min-width: 1920px) {
    font-size: 88px;
    line-height: 90px;
  }
}

.third-title {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: uppercase;


  @media screen and (min-width: 1200px) {
    font-size: 36px;
    line-height: 48px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 48px;
    line-height: 64px;
  }
}

.mob {
  @media screen and (min-width: 1250px) {
    display: none;
  }
}

.desk {
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }
}

.animated {
  .a-title {
    animation: title 1s 0.5s 1 alternate backwards;
  }

  .a-list {
    li {
      animation: list 1s 1 alternate backwards;

      &:nth-child(1) {
        animation-delay: 1s;
      }

      &:nth-child(2) {
        animation-delay: 1.2s;
      }

      &:nth-child(3) {
        animation-delay: 1.4s;
      }

      &:nth-child(4) {
        animation-delay: 1.6s;
      }

      &:nth-child(5) {
        animation-delay: 1.8s;
      }

      &:nth-child(6) {
        animation-delay: 2s;
      }

      &:nth-child(7) {
        animation-delay: 2.2s;
      }

      &:nth-child(8) {
        animation-delay: 2.4s;
      }

      &:nth-child(9) {
        animation-delay: 2.6s;
      }

      &:nth-child(10) {
        animation-delay: 2.8s;
      }
    }
  }
}

@keyframes title {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes list {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
