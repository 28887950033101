import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing, {
  networks: {
    instagram: "https://instagram.com/share?url=@url&title=@title",
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,

  render: function (h) {
    return h(App);
  },
}).$mount("#app");
