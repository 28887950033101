<template>
  <div class="popup">
    <div class="popup__container">
      <button
        @click="
          setIsActiveOrder(false);
          setOneTrigger();
        "
        class="popup__closer"
      >
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4375 16L22.3315 21.9873C22.6318 22.2924 22.6318 22.787 22.3315 23.0921L16.4375 29.0795"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M29.3125 16L23.4185 21.9873C23.1182 22.2924 23.1182 22.787 23.4185 23.0921L29.3125 29.0795"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <circle cx="23" cy="23" r="22.5" stroke="white" />
        </svg>
      </button>

      <div class="popup__image-block">
        <h2 class="popup__title">
          <p class="popup__title-s">
            GOOD<br />
            NEWS!
          </p>
          <p class="popup__title-l">B+C</p>
        </h2>

        <img
          srcset="
            @/assets/img/status_packs(x2).png 1024w,
            @/assets/img/status_packs(x3).png 1920w
          "
          sizes="(max-width: 640px) 200px,
                      (max-width: 1024px) 250px,
                      272px"
          src="@/assets/img/status_packs(x1).png"
          alt=""
          decoding="async"
          loading="lazy"
          class="popup__img"
        />
      </div>

      <div class="popup__bottom-block">
        <p class="popup__text">
          Замовляйте онлайн та отримайте безкоштовну доставку
        </p>
        <a
          href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173225&promocode=frmsk&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=hepatit_b_c"
          class="popup__button"
          target="_blank"
        >
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7007 12.2334L17.315 12.6189L17.7326 12.9698L21.4785 16.1171C21.4785 16.1172 21.4785 16.1172 21.4786 16.1172C21.505 16.1396 21.5064 16.1802 21.4827 16.2039L21.4827 16.2039L18.6568 19.0297C18.6336 19.0529 18.5935 19.0533 18.5701 19.0257C18.5701 19.0256 18.5701 19.0256 18.57 19.0256L15.4226 15.2797L15.0717 14.8621L14.6861 15.2479L13.1329 16.8018C13.1329 16.8018 13.1329 16.8018 13.1329 16.8018C13.1328 16.8018 13.1328 16.8018 13.1328 16.8019C13.1227 16.8119 13.1144 16.8155 13.1076 16.8173C13.0993 16.8194 13.0885 16.8198 13.0767 16.8168C13.0649 16.8138 13.0558 16.8084 13.0497 16.8028C13.0447 16.7982 13.0393 16.7914 13.0354 16.7781L13.0351 16.7773L10.4195 8.03939L10.4195 8.03939L10.4191 8.03799C10.4147 8.02353 10.4158 8.01482 10.4175 8.00859C10.4197 8.00061 10.4249 7.99067 10.4341 7.98146C10.4433 7.97225 10.4532 7.96707 10.4613 7.96488C10.4675 7.96317 10.4763 7.96207 10.4907 7.96643L10.4907 7.96643L10.4921 7.96685L19.2294 10.5822C19.2295 10.5822 19.2296 10.5822 19.2296 10.5823C19.244 10.5866 19.2513 10.5925 19.2559 10.5975C19.2616 10.6037 19.2669 10.6127 19.2698 10.6243C19.2727 10.6358 19.2723 10.6465 19.2701 10.6548C19.2684 10.6618 19.2647 10.6701 19.2547 10.6801L19.2547 10.6802L17.7007 12.2334Z"
              fill="#F3E6A1"
              stroke="#F3E6A1"
            />
            <path
              d="M3.85727 6.66457L3.85731 6.66458C3.85739 6.66461 3.85747 6.66463 3.85755 6.66466C3.85757 6.6649 3.8576 6.66516 3.85762 6.66543C3.8577 6.66643 3.85771 6.66731 3.85768 6.66805C3.85763 6.66807 3.85758 6.6681 3.85753 6.66812C3.85712 6.66834 3.85682 6.66843 3.85668 6.66846C3.85658 6.66849 3.85656 6.66849 3.85663 6.66848C3.85685 6.66846 3.85698 6.66848 3.85696 6.66848C3.85692 6.66848 3.85661 6.66844 3.85589 6.66821L3.01932 6.40095C3.01819 6.40059 3.01836 6.40049 3.01874 6.40082C3.01885 6.40092 3.01855 6.40068 3.0182 6.4C3.01785 6.39933 3.01783 6.39894 3.01784 6.39908C3.01789 6.39958 3.01772 6.39966 3.01806 6.39859C3.01806 6.39859 3.01806 6.39859 3.01806 6.39859C3.01817 6.39824 3.01826 6.39801 3.01832 6.39787C3.01845 6.39776 3.01869 6.39759 3.01906 6.3974C3.01942 6.39721 3.01971 6.39711 3.01986 6.39707C3.02002 6.3971 3.02026 6.39717 3.02063 6.39728L3.85727 6.66457Z"
              fill="white"
              stroke="#F3E6A1"
            />
            <path
              d="M7.58195 1.90655L7.58192 1.90651C7.58188 1.90644 7.58183 1.90637 7.58179 1.90631C7.58154 1.90634 7.58129 1.90639 7.58102 1.90644C7.58003 1.90663 7.57919 1.90685 7.57848 1.90708C7.57847 1.90713 7.57846 1.90718 7.57845 1.90723C7.57835 1.90769 7.57834 1.90801 7.57835 1.90815C7.57835 1.90825 7.57836 1.90827 7.57834 1.9082C7.5783 1.90798 7.57825 1.90786 7.57825 1.90789C7.57826 1.90792 7.57838 1.90821 7.5788 1.90885L8.05765 2.64503C8.05829 2.64603 8.05834 2.64584 8.05792 2.64556C8.05781 2.64548 8.05812 2.64571 8.05886 2.64587C8.0596 2.64602 8.05998 2.64594 8.05984 2.64597C8.05935 2.64605 8.05931 2.64624 8.06026 2.64563C8.06026 2.64563 8.06026 2.64563 8.06026 2.64563C8.06057 2.64543 8.06077 2.64528 8.06089 2.64518C8.06095 2.64503 8.06106 2.64476 8.06115 2.64435C8.06123 2.64395 8.06125 2.64365 8.06125 2.64349C8.06117 2.64335 8.06105 2.64313 8.06084 2.64281L7.58195 1.90655Z"
              fill="white"
              stroke="#F3E6A1"
            />
            <path
              d="M14.2481 0.690826L14.2481 0.690778C14.2481 0.690698 14.2481 0.690619 14.2481 0.69054C14.2479 0.690389 14.2477 0.690235 14.2475 0.690076C14.2467 0.689487 14.246 0.689028 14.2453 0.688672C14.2453 0.6887 14.2452 0.688728 14.2452 0.688756C14.2448 0.689002 14.2445 0.689212 14.2444 0.689314C14.2444 0.689383 14.2444 0.689402 14.2444 0.689348C14.2445 0.689166 14.2446 0.689041 14.2446 0.689064C14.2445 0.689094 14.2444 0.689379 14.2442 0.69012L14.0401 1.54428C14.0398 1.54543 14.04 1.54534 14.0399 1.54485C14.0399 1.5447 14.0399 1.54509 14.0403 1.54573C14.0407 1.54638 14.041 1.5466 14.0409 1.54651C14.0405 1.54622 14.0404 1.54632 14.0415 1.54658C14.0415 1.54658 14.0415 1.54658 14.0415 1.54658C14.0418 1.54667 14.0421 1.54671 14.0422 1.54673C14.0424 1.54668 14.0426 1.54656 14.043 1.54635C14.0433 1.54613 14.0436 1.54594 14.0437 1.54582C14.0437 1.54568 14.0438 1.54543 14.0439 1.54506L14.2481 0.690826Z"
              fill="white"
              stroke="#F3E6A1"
            />
            <path
              d="M20.3411 2.83862L20.3411 2.83858C20.3412 2.83852 20.3412 2.83845 20.3413 2.83839C20.3411 2.83817 20.341 2.83795 20.3409 2.83771C20.3404 2.83685 20.3399 2.83613 20.3394 2.83555C20.3393 2.83555 20.3393 2.83556 20.3392 2.83557C20.3388 2.83564 20.3385 2.83574 20.3383 2.83579C20.3383 2.83583 20.3382 2.83584 20.3383 2.83581C20.3385 2.83569 20.3386 2.8356 20.3386 2.83561C20.3385 2.83563 20.3383 2.83584 20.3379 2.83646L19.8121 3.5399C19.8114 3.54085 19.8116 3.54084 19.8117 3.54035C19.8117 3.54021 19.8116 3.54058 19.8117 3.54133C19.8118 3.54208 19.812 3.5424 19.812 3.54228C19.8117 3.54185 19.8115 3.54188 19.8124 3.54256C19.8124 3.54256 19.8124 3.54256 19.8124 3.54256C19.8127 3.54278 19.8129 3.54292 19.8131 3.543C19.8132 3.54301 19.8135 3.54301 19.8139 3.54295C19.8143 3.54289 19.8146 3.5428 19.8148 3.54275C19.8149 3.54263 19.815 3.54244 19.8153 3.54213L20.3411 2.83862Z"
              fill="white"
              stroke="#F3E6A1"
            />
            <path
              d="M2.31701 12.9557L2.31696 12.9557C2.31687 12.9557 2.31679 12.9558 2.31671 12.9558C2.31659 12.9555 2.31647 12.9553 2.31634 12.9551C2.31587 12.9542 2.31551 12.9534 2.31525 12.9527C2.31528 12.9527 2.31532 12.9526 2.31535 12.9526C2.31567 12.9522 2.31593 12.952 2.31603 12.9519C2.31609 12.9519 2.31609 12.9519 2.31604 12.9519C2.31584 12.952 2.3157 12.952 2.31574 12.952C2.31579 12.952 2.31609 12.9519 2.31683 12.9518L3.19084 12.866C3.19203 12.8659 3.19191 12.8661 3.19143 12.8659C3.19129 12.8659 3.19166 12.866 3.19225 12.8665C3.19284 12.8669 3.19301 12.8673 3.19294 12.8672C3.1927 12.8667 3.19282 12.8666 3.19294 12.8677C3.19294 12.8677 3.19294 12.8677 3.19294 12.8677C3.19297 12.8681 3.19298 12.8683 3.19299 12.8685C3.19291 12.8686 3.19276 12.8689 3.1925 12.8692C3.19224 12.8695 3.19201 12.8697 3.19188 12.8698C3.19173 12.8698 3.19148 12.8699 3.1911 12.8699L2.31701 12.9557Z"
              fill="white"
              stroke="#F3E6A1"
            />
            <path
              d="M5.53158 18.9213L5.53155 18.9213C5.5315 18.9214 5.53145 18.9215 5.5314 18.9215C5.53116 18.9215 5.53091 18.9214 5.53065 18.9213C5.52967 18.9211 5.52885 18.9208 5.52817 18.9205C5.52816 18.9204 5.52815 18.9204 5.52815 18.9203C5.52809 18.9199 5.52811 18.9196 5.52812 18.9194C5.52813 18.9193 5.52814 18.9193 5.52813 18.9194C5.52807 18.9196 5.528 18.9197 5.52801 18.9197C5.52802 18.9196 5.52817 18.9194 5.52863 18.9188L6.06822 18.2259C6.06895 18.2249 6.06898 18.2251 6.06854 18.2254C6.06842 18.2254 6.06874 18.2252 6.0695 18.2251C6.07025 18.225 6.07062 18.2251 6.07048 18.2251C6.07 18.225 6.06998 18.2248 6.07087 18.2255C6.07087 18.2255 6.07087 18.2255 6.07087 18.2255C6.07116 18.2257 6.07135 18.2259 6.07146 18.226C6.07151 18.2261 6.0716 18.2264 6.07165 18.2268C6.0717 18.2272 6.07169 18.2275 6.07167 18.2277C6.07159 18.2278 6.07145 18.228 6.07121 18.2283L5.53158 18.9213Z"
              fill="white"
              stroke="#F3E6A1"
            />
          </svg>
          <p>Замовити</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "PopupOrder",
  methods: {
    ...mapMutations(["setIsActiveOrder", "setOneTrigger"]),
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  &__container {
    position: relative;

    width: 100%;
    max-width: 360px;
    max-height: 80%;

    margin: 0 24px;

    @media screen and (min-width: 768px) {
      min-width: 360px;
    }
  }

  &__closer {
    position: absolute;
    right: -52px;
    top: -52px;

    cursor: pointer;
    @media screen and (max-width: 600px) {
      right: -6px;
      top: -58px;
    }
  }

  &__image-block {
    display: flex;
    flex-direction: column;

    padding: 22px 16px;

    background: linear-gradient(269.66deg, #f14f1d -0.95%, #ef3636 99.7%);

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: "Oswald", sans-serif;

    @media screen and (min-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &-s {
      font-weight: 600;
      font-size: 54px;
      line-height: 56px;

      text-transform: uppercase;

      color: #f3e6a1;

      @media screen and (min-width: 768px) {
        font-size: 41.5042px;
        line-height: 42px;
      }
    }

    &-l {
      font-weight: bold;
      font-size: 119.827px;
      line-height: 96px;
      text-transform: uppercase;

      color: #ffffff;

      @media screen and (min-width: 768px) {
        font-size: 70.1146px;
        line-height: 71px;
      }
    }
  }

  &__img {
    margin: 0 auto;

    width: 80%;

    transform: translateY(50px);

    @media screen and (min-width: 768px) {
      max-width: 230px;
    }
  }

  &__bottom-block {
    height: 40%;

    padding: 65px 24px 43px 24px;

    background: #fff;
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #000000;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 240px;
    height: 48px;

    margin: 24px auto 0;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #ffffff;
    background-color: #ed0000;
    border: 1px solid #000;
    border-radius: 120px;

    svg {
      margin-right: 8px;
    }

    &:active {
      background: #c83b26;
    }
  }
}
</style>
