<template>
  <div class="popup">
    <div class="popup__container">
      <button @click="setIsActiveVideo(false)" class="popup__closer">
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4375 16L22.3315 21.9873C22.6318 22.2924 22.6318 22.787 22.3315 23.0921L16.4375 29.0795"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M29.3125 16L23.4185 21.9873C23.1182 22.2924 23.1182 22.787 23.4185 23.0921L29.3125 29.0795"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <circle cx="23" cy="23" r="22.5" stroke="white" />
        </svg>
      </button>

      <video src=""></video>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["setIsActiveVideo"]),
  },
};
</script>

<style scoped lang="scss">
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  &__container {
    position: relative;

    width: 100%;
    max-width: 360px;
    max-height: 80%;

    margin: 0 24px;

    background-color: #fff;

    @media screen and (min-width: 768px) {
      min-width: 360px;
    }
  }

  &__closer {
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);

    background: transparent;

    cursor: pointer;
  }
}
</style>
