import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isActiveSocials: false,
    isActiveOrder: false,
    isActiveVideo: false,

    oneTrigger: false,

    visibleStage: [],

    stage: 0,
    stageBar: 1,
    isStageEnable: false,
    answers: {},
    result: null,
    results: [
      {
        title: "Якщо маєш сумніви щодо наявності вірусного гепатиту В або С,",
        text: "повідом про це батьків та разом зверніться до свого сімейного лікаря для уточнення деталей",
        type: "<16",
      }, // 0

      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: true,
        risk: "H",
      }, //1
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "B",
        dop: true,
        risk: "H",
      }, //2
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "C",
        dop: true,
        risk: "H",
      }, //3
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: true,
        risk: "H",
      }, //4
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: false,
        risk: "H",
      }, // 5
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "C",
        dop: false,
        risk: "H",
      }, //6
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "H",
      }, //7

      //
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "BC",
        dop: true,
        risk: "H",
      }, //8
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "B",
        dop: true,
        risk: "H",
      }, //9
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "C",
        dop: true,
        risk: "H",
      }, //10
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації, тому тестуйтеся щороку.",
        type: "G",
        dop: true,
        risk: "H",
      }, //11
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "BC",
        dop: false,
        risk: "H",
      }, //12
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "C",
        dop: false,
        risk: "H",
      }, //13
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "H",
      }, //14

      //

      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: true,
        risk: "H",
      }, //15
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "B",
        dop: true,
        risk: "H",
      }, //16
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "C",
        dop: true,
        risk: "H",
      }, //17
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "H",
      }, //18
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: false,
        risk: "H",
      }, //19
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "C",
        dop: false,
        risk: "H",
      }, //20
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "H",
      }, //21

      //
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "BC",
        dop: true,
        risk: "M",
      }, //22
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "B",
        dop: true,
        risk: "M",
      }, //23
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "C",
        dop: true,
        risk: "M",
      }, //24
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації, тому тестуйтеся щороку.",
        type: "G",
        dop: true,
        risk: "M",
      }, //25
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "BC",
        dop: false,
        risk: "M",
      }, //26
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "C",
        dop: false,
        risk: "M",
      }, //27
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "M",
      }, //28

      // // //
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: true,
        risk: "H",
      }, //29
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "B",
        dop: true,
        risk: "H",
      }, //30
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "C",
        dop: true,
        risk: "H",
      }, //31
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "H",
      }, //32
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: false,
        risk: "H",
      }, //33
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "C",
        dop: false,
        risk: "H",
      }, //34
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: true,
        risk: "H",
      }, //35

      //
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "BC",
        dop: true,
        risk: "M",
      }, //36
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "B",
        dop: true,
        risk: "M",
      }, //37
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "C",
        dop: true,
        risk: "M",
      }, //38
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації, тому тестуйтеся щороку.",
        type: "G",
        dop: true,
        risk: "M",
      }, //39
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "BC",
        dop: false,
        risk: "M",
      }, //40
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації, проходьте тестування щороку.",
        type: "C",
        dop: false,
        risk: "M",
      }, //41
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "M",
      }, //42

      //

      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>", // питання 1 з табл
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: true,
        risk: "M",
      }, //43
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "B",
        dop: true,
        risk: "M",
      }, //44
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку. ",
        type: "C",
        dop: true,
        risk: "M",
      }, // 45
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: true,
        risk: "M",
      }, //46
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "BC",
        dop: false,
        risk: "M",
      }, //47
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Зважаючи на Ваші буденні потенційно небезпечні ситуації та професійні ризики, проходьте тестування щороку.",
        type: "C",
        dop: false,
        risk: "M",
      }, //48
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про Ваші буденні потенційно небезпечні ситуації та професійні ризики, тому тестуйтеся щороку.",
        type: "G",
        dop: false,
        risk: "M",
      }, //49

      //
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "",
        type: "BC",
        dop: true,
        risk: "L",
      }, //50
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;В",
        text: "",
        type: "B",
        dop: true,
        risk: "L",
      }, //51
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "",
        type: "C",
        dop: true,
        risk: "L",
      }, //52
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про потенційно небезпечні ситуації та місця, де можна інфікуватися гепатитами В та С. У разі виникнення сумнівів, протестуйтеся вдома з CITO TEST®.",
        type: "G",
        dop: true,
        risk: "L",
      }, //53
      {
        title:
          "Вам рекомендовано перевірятися на вірусні гепатити В та С <strong>щороку</strong>",
        text: "Пам'ятайте про потенційно небезпечні ситуації та місця, де можна інфікуватися гепатитами В та С. У разі виникнення сумнівів, протестуйтеся вдома з CITO TEST®.",
        type: "BC",
        dop: false,
        risk: "L",
      }, //54
      {
        title: "Вам рекомендовано перевіритися на вірусний гепатит&nbsp;С",
        text: "Пам'ятайте про потенційно небезпечні ситуації та місця, де можна інфікуватися гепатитами В та С. У разі виникнення сумнівів, протестуйтеся вдома з CITO TEST®.",
        type: "C",
        dop: false,
        risk: "L",
      }, //55
      {
        title: "Ви знаєте свій статус",
        text: "Пам'ятайте про потенційно небезпечні ситуації та місця, де можна інфікуватися гепатитами В та С. У разі виникнення сумнівів, протестуйтеся вдома з CITO TEST®.",
        type: "G",
        dop: false,
        risk: "L",
      }, // 56
    ],
  },
  getters: {
    getIsActiveSocials(state) {
      return state.isActiveSocials;
    },
    getIsActiveOrder(state) {
      return state.isActiveOrder;
    },
    getIsActiveVideo(state) {
      return state.isActiveVideo;
    },

    getVisibleStage(state) {
      return state.visibleStage;
    },

    getOneTrigger(state) {
      return state.oneTrigger;
    },

    getStage(state) {
      return state.stage;
    },
    getStageBar(state) {
      return state.stageBar;
    },
    getIsStageEnable(state) {
      return state.isStageEnable;
    },
    getAnswers(state) {
      return state.answers;
    },
    getResult(state) {
      return state.result;
    },
  },
  mutations: {
    setOneTrigger(state) {
      state.oneTrigger = true;
    },

    setIsActiveSocials(state, value) {
      state.isActiveSocials = value;
    },
    setIsActiveOrder(state, value) {
      state.isActiveOrder = value;
    },
    setIsActiveVideo(state, value) {
      state.isActiveVideo = value;
    },

    setVisibleStage(state, value) {
      state.visibleStage.push(value);
    },

    setStage(state, value) {
      state.stage = value;
    },
    setStageBar(state, value) {
      state.stageBar = value;
    },
    setIsStageEnable(state, value) {
      state.isStageEnable = value;
    },
    setAnswers(state, value) {
      state.answers = {
        ...state.answers,
        [value.name]: value.value,
      };
    },
    setResult(state, value) {
      state.result = value;
    },
    setNull(state) {
      state.stage = 0;
      state.stageBar = 1;
      state.isStageEnable = false;
      state.answers = {};
      state.result = null;
    },
  },
  actions: {
    clearTest(context) {
      context.commit("setNull");
    },
    setResult(context) {
      const { answers, results } = this.state;

      if (answers.q0_1) {
        if (answers.q0.includes(0)) {
          context.commit("setResult", results[0]);
        } else {
          context.commit("setResult", null);
        }
      }

      if (answers.q7 && answers.q0.includes(1)) {
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[1]);
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[2]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[3]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[3]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[4]);
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[4]); // exel 39
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[5]);
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[6]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[7]);
        }

        //
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[8]);
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[9]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[10]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[10]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[11]); // exel 86, тест 2 чоловічий алгоритм
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[11]); // exel 38
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[12]);
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[13]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[14]);
        }

        // //
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[15]);
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[16]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[17]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[17]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[4]); // exel 85
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[35]); // exel 37
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[19]);
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[20]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[21]);
        }

        //
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[22]);
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[23]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[24]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[24]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[25]);
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[25]);
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[26]);
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[27]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          !answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[28]);
        }

        // // //
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[29]);
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[30]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[31]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[31]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[4]);
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[35]); // exel 35
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[33]);
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[34]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[35]);
        }

        //
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[36]);
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[37]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[38]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[38]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[39]);
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[39]); // exel 34
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[40]);
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[41]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[42]);
        }

        // //
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[43]);
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[44]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[45]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[45]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[46]);
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[46]);
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[47]);
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[48]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[49]);
        }

        //
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[22]); // №1 табл ексель
        }
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3_1 &&
          (answers.q3_1.includes(0) || answers.q3_1.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[36]); // №1 табл ексель лист 2
        }
        if (
          answers.q1.includes(0) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3_1 &&
          (answers.q3_1.includes(0) || answers.q3_1.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[50]); // №4 табл ексель лист 2
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[23]); // 18 exel
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[24]);
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[24]);
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1)) // №4,5,6 табл ексель
        ) {
          context.commit("setResult", results[24]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[25]); // exel 77
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[25]); // exel 29
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[26]); // exel 62
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[27]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[28]); // exel 88
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3_1 &&
          (answers.q3_1.includes(0) || answers.q3_1.includes(1)) &&
          answers.q3.includes(7) &&
          (answers.q4.includes(3) ||
            answers.q4.includes(0) ||
            answers.q4.includes(1) ||
            answers.q4.includes(2)) &&
          !answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[7]); // №7 табл ексель лист 2
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3_1 &&
          (answers.q3_1.includes(0) || answers.q3_1.includes(1)) &&
          !answers.q3.includes(7) &&
          !answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          (answers.q6.includes(0) || answers.q6.includes(1)) &&
          (answers.q7.includes(0) || answers.q7.includes(1))
        ) {
          context.commit("setResult", results[11]); // №4 табл ексель тест 2 жіночий алгоритм
        }

        if (
          answers.q1.includes(1) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[51]); // exel 21
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[52]); // exel 43, 54
        }

        if (
          answers.q1.includes(2) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3_1 &&
          (answers.q3_1.includes(0) || answers.q3_1.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[52]); // №5 табл ексель лист 2
        }

        if (
          answers.q1.includes(0) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[52]); // №7 табл ексель
        }

        if (
          answers.q1.includes(0) &&
          !answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[50]);
        }

        if (
          answers.q1.includes(4) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[53]);
        }

        if (
          answers.q1.includes(1) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[53]); // exel 32
        }

        if (
          answers.q1.includes(3) &&
          (answers.q2.includes(0) || answers.q2.includes(1)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[54]); // exel 65
        }

        if (
          answers.q1.includes(3) &&
          answers.q2.includes(2) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[55]);
        }

        if (
          answers.q1.includes(5) &&
          (answers.q2.includes(0) ||
            answers.q2.includes(1) ||
            answers.q2.includes(2)) &&
          answers.q3.includes(7) &&
          answers.q4.includes(3) &&
          answers.q5.includes(3) &&
          answers.q6.includes(1) &&
          answers.q7.includes(1)
        ) {
          context.commit("setResult", results[56]); // exel 91
        }
      }
    },
  },
  modules: {},
});
